import React, { useState } from "react";
import RegisterRoles from "../../commons/registerRoles";
import { FaEdit } from 'react-icons/fa';


const UserProfile = ({ user }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [uid, setUid] = useState(user && user._id || '');

    const handleEditClick = () => {
        setIsEditing(!isEditing);
    };


    return (
        <div className="overflow-hidden rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
            <section>
                <img
                    src="https://res.cloudinary.com/dm71xhdxd/image/upload/v1728995176/KAB_web_banner_fw5hkq.png"
                    alt="home.pic"
                    className="object-cover"
                    style={{
                        width: "100%",
                        cursor: "pointer",
                        height: "100%"
                    }}
                />
            </section>
            <div className="px-4 pb-6 text-center lg:pb-8 xl:pb-11.5">
                <div className="relative z-30 mx-auto -mt-8 sm:-mt-22 h-16 w-full max-w-16 rounded-full p-1 backdrop-blur sm:h-44 sm:max-w-44 sm:p-3">
                    <div className="relative drop-shadow-2">
                        {user && user.photo ? (
                            <img
                                src={user.photo}
                                alt="profile"
                                className="w-16 h-16 sm:w-full sm:h-28 md:h-36 rounded-full"
                            />
                        ) : (
                            <div className="bg-green-300 w-16 h-16 sm:w-full sm:h-28 md:h-36  flex items-center justify-center bg-gray-300 rounded-full text-lg font-semibold text-white">
                                {`${user && user.username.charAt(0) || ''}${user && user.username.split(' ')[1]?.charAt(0) || ''}`}
                            </div>
                        )}
                    </div>
                </div>
                <h3 className="mt-6 text-xl font-semibold text-gray-600 dark:text-white">{user && user.username}</h3>
                <span className="block text-base font-medium text-body-dark">{user && user.roleID.name}</span>
                <div className="mt-8 flex flex-wrap items-center justify-center gap-5">
                    <button
                        onClick={handleEditClick}
                        className="flex items-center justify-center rounded-md border 
                        border-success bg-transparent px-5 py-2.5 text-base font-medium
                        hover:bg-success hover:text-white"
                    >
                        <FaEdit className="mr-2" /> {/* Add the edit icon here */}
                        Edit Profile
                    </button>
                </div>
                {isEditing && <RegisterRoles user={user} />}
            </div>
        </div>
    );
};

export default UserProfile;
