import React from "react";
import CategorySection from "./categorySection";
import ProductSuggetions from "../product/productSuggest";
import CategorySuggetions from "../product/categorySuggest";
import SerachBox from "./searchbox";


const Home = ({ user, isproPage }) => {

    return (
        <>
            {/* Hero Section */}
            <section>
                <img
                    src="https://res.cloudinary.com/dm71xhdxd/image/upload/v1728995176/KAB_web_banner_fw5hkq.png"
                    alt="home.pic"
                    className="object-cover"
                    style={{
                        width: "100%",
                        cursor: "pointer",
                        height: "100%"
                    }}
                />
            </section>

            <SerachBox />

            {/* Category Sections */}
            <div>
                <CategorySection />
            </div>

            {/* Product You May Like Sections */}
            <div>
                <ProductSuggetions user={user} isproPage={isproPage} />
            </div>

            {/* Categories You May Like Sections */}
            <div>
                <CategorySuggetions />
            </div>
        </>
    );
};

export default Home;
